import React, { FC } from 'react';
import { ChevronRight } from '@wix/wix-ui-icons-common/on-stage';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';
import { TextButton } from 'wix-ui-tpa';
import { CatalogServiceDto, ServiceType } from '@wix/bookings-uou-types';
import { classes, st } from './ExplorePlans.st.css';
import settingsParams from '../../../../../settingsParams';
import { DataHooks } from './consts';
import { useWidgetActions } from '../../../../hooks/useWidgetActions';
import { ReferralInfo } from '../../../../../../../utils/bi/consts';
import { useWidgetViewModel } from '../../../../hooks/useWidgetViewModel';
import { showExplorePlansForService } from '../../../../../../../utils/serviceDetails/showExplorePlansForService';

export interface ExplorePlansProps {
  service: CatalogServiceDto;
}

export const ExplorePlans: FC<ExplorePlansProps> = ({ service }) => {
  const { isMobile } = useEnvironment();
  const settings = useSettings();
  const { t } = useTranslation();
  const {
    bodyViewModel: { atLeastOneServiceHasExplorePlansLink },
  } = useWidgetViewModel();
  const { navigateToPricingPlan } = useWidgetActions();

  if (!atLeastOneServiceHasExplorePlansLink) {
    return null; // if no service shows an Explore Plans link, we don't need to render anything. But if at least one service shows an Explore Plans link, we need to render a placeholder empty link for services without the link, so the Book buttons are aligned on all services.
  }

  const showLink = showExplorePlansForService(service);
  const linkText = showLink
    ? settings.get(settingsParams.explorePlansText) ||
      t('info-card.design.explore-plans')
    : '';

  return (
    <TextButton
      onClick={() => navigateToPricingPlan(service, ReferralInfo.EXPLORE_PLANS)}
      className={st(classes.link, { isMobile, isEmpty: !showLink })}
      data-hook={DataHooks.LINK}
      suffixIcon={
        showLink ? (
          <ChevronRight size={isMobile ? 'xSmall' : 'small'} />
        ) : undefined
      }
    >
      {linkText}
    </TextButton>
  );
};
